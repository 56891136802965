<template>
  <div id="form-crud-shop" class="mb-5">
    <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-5" v-if="loader.isLoading">
      <div class="text-center">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
    <div v-else>
      <form @submit.prevent="initSubmit">
        <div class="d-flex flex-wrap">
          {{ /* BLOCK LEFT */ }}
          <div class="col-12 col-md-6">
            <h3>Nom, TVA, lot & surface</h3>
            <hr />
            <div class="col-12">
              <div class="form-group">
                <label for="name">Nom</label>
                <input class="form-control" id="name" v-model="shop.name" type="text">
                <div v-if="errorsForm.name">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.name.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="vat-rate">TVA</label>
                <input class="form-control" id="vat-rate" v-model="shop.vatRate" type="number" step="0.01">
                <div v-if="errorsForm.vatRate">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.vatRate.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="lot">Numéro de lot</label>
                <input class="form-control" id="lot" v-model="shop.lot" type="text">
                <div v-if="errorsForm.lot">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.lot.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="surface">Surface</label>
                <input class="form-control" id="surface" v-model="shop.surface" type="number">
                <div v-if="errorsForm.surface">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.surface.errors" :key="index">{{ error }}</p>
                </div>
              </div>
            </div>

            <h3 class="mt-4 mb-4">Contact</h3>
            <hr />

            <div class="col-12">
              <div class="form-group">
                <label for="contact-firstname">Prénom du contact</label>
                <input class="form-control" id="contact-firstname" v-model="shop.contactFirstname" type="tel">
                <div v-if="errorsForm.contactFirstname">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.contactFirstname.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="contact-lastname">Nom du contact</label>
                <input class="form-control" id="contact-lastname" v-model="shop.contactLastname" type="text">
                <div v-if="errorsForm.contactLastname">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.contactLastname.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="contact-email">Email du contact</label>
                <input class="form-control" id="contact-email" v-model="shop.contactEmail" type="email">
                <div v-if="errorsForm.contactEmail">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.contactEmail.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="contact-phone">Téléphone du contact</label>
                <input class="form-control" id="contact-phone" v-model="shop.contactPhone" type="tel">
                <div v-if="errorsForm.contactPhone">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.contactPhone.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="contact-mobile-phone">
                  Téléphone mobile du contact

                  <b-alert class="mt-2" show variant="info">Attention : le numéro de mobile servira pour l'envoie des SMS lors de l'alerting !</b-alert>
                </label>
                <input class="form-control" id="contact-mobile-phone" v-model="shop.contactMobilePhone" type="tel">
                <div v-if="errorsForm.contactMobilePhone">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.contactMobilePhone.errors" :key="index">{{ error }}</p>
                </div>
              </div>
            </div>

            <h3 class="mt-3 mb-3">Date(s)</h3>
            <hr />

            <div class="col-12">
              <div class="form-group">
                <label for="date-open">Date d'ouverture</label>
                <Datepicker input-class="w-100" id="date-open" v-model="shop.openDate" :format="formatDate"></Datepicker>
                <div v-if="errorsForm.openDate">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.openDate.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="close-date">Date de fermeture</label>
                <div class="position-relative">
                  <Datepicker input-class="w-100" id="close-date" v-model="shop.closeDate" :format="formatDate"></Datepicker>
                  <b-icon class="icon-x-datepicker" icon="x" font-scale="1.5" v-on:click="shop.closeDate = null"></b-icon>
                </div>
                <div v-if="errorsForm.closeDate">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.closeDate.errors" :key="index">{{ error }}</p>
                </div>
              </div>
              <div class="form-group">
                <label for="hull-date">Date de livraison de coque</label>
                <Datepicker input-class="w-100" id="hull-date" v-model="shop.hullDate" :format="formatDate"></Datepicker>
                <div v-if="errorsForm.hullDate">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.hullDate.errors" :key="index">{{ error }}</p>
                </div>
              </div>
            </div>

            <h3 class="mt-4 mb-4">Affecter le commerce à un centre, une entreprise ou une catégorie</h3>
            <hr />

            <div class="col-12">
              <div class="form-group">
                <label for="select-company">Entreprise</label>
                <select id="select-company" class="form-control" v-model="selectCompany" @input="changeCompany">
                  <option v-for="(company, index) in CompanyModule.companies" :key="index" :value="company.id">
                    {{ company.name }}
                  </option>
                </select>
                <div v-if="errorsForm.company">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.company.errors" :key="index">{{ error }}</p>
                </div>
              </div>

              <div class="form-group">
                <label for="select-category">Categorie</label>
                <select id="select-category" class="form-control" v-model="selectCategory" @input="changeCategory">
                  <template v-for="(category) in CategoryModule.categories" :value="category.id">
                    <option :key="category.id" :value="category.id" v-if="category.parent">
                      {{ category.name }} | Parent:  {{ (category.parent != null) ? category.parent.name : '' }}
                    </option>
                  </template>
                </select>
                <div v-if="errorsForm.category">
                  <p class="form-error text-danger" v-for="(error, index) in errorsForm.category.errors" :key="index">{{ error }}</p>
                </div>
              </div>
            </div>
          </div>
          {{ /* BLOCK RIGHT */ }}
          <div class="col-12 col-md-6">
            <h3 class="">Config(s)</h3>
            <hr />

            <div class="col-12">
              <div class="form-group">
                <b-alert show variant="info">
                  <h4 class="alert-heading">Double Saisie</h4>
                  <hr>
                  <p>
                    Si vous choissisez la double saisie à <b>"OUI"</b>,
                    le calcul entre le <b>H.T</b> et <b>T.T.C</b> sera saisie <b>manuellement</b>, lors des saisies chiffres d'affaires.
                    <br />
                    <br />
                    Si vous choissisez la double saisie à <b>"NON"</b>,
                    le calcul entre le <b>H.T</b> et <b>T.T.C</b> sera saisie <b>automatiquement</b>, lors des saisies chiffres d'affaires.
                  </p>
                  <hr />
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="double-sale-entry-yes" v-model="shop.doubleSaleEntry" :checked="shop.doubleSaleEntry === true" v-bind:value="true">
                    <label class="form-check-label" for="double-sale-entry-yes">
                      Oui
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="double-sale-entry-no" v-model="shop.doubleSaleEntry" :checked="shop.doubleSaleEntry === false" v-bind:value="false">
                    <label class="form-check-label" for="double-sale-entry-no">
                      non
                    </label>
                  </div>
                  <div v-if="errorsForm.doubleSaleEntry">
                    <p class="form-error text-danger" v-for="(error, index) in errorsForm.doubleSaleEntry.errors" :key="index">{{ error }}</p>
                  </div>
                </b-alert>
              </div>
              <div class="form-group">
                <b-alert show variant="info">
                  <h4 class="alert-heading">Choisir le mode de saisie chiffre d'affaire</h4>
                  <hr>
                  <p>
                    Si vous choissisez <b>"Au mois"</b>,
                    le magasin n'aura qu'une entrée pour le <b>mois</b> lors des saisies chiffres d'affaires.
                    <br />
                    <br />
                    Si vous choissisez <b>"Journalier"</b>,
                    le magasin aura une entrée par <b>jours</b>
                    avec obligation de remplir chaques jours, lors des saisies chiffres d'affaires.
                  </p>
                  <hr />
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="is-month-yes" v-model="shop.isMonth" :checked="shop.isMonth === true"  v-bind:value="true">
                    <label class="form-check-label" for="is-month-yes">
                      Au mois
                    </label>
                  </div>
                  <div class="form-check" >
                    <input class="form-check-input" type="radio" id="is-month-no" v-model="shop.isMonth" :checked="shop.isMonth === false" v-bind:value="false">
                    <label class="form-check-label" for="is-month-no">
                      Journalier
                    </label>
                  </div>
                  <div v-if="errorsForm.isMonth">
                    <p class="form-error text-danger" v-for="(error, index) in errorsForm.isMonth.errors" :key="index">{{ error }}</p>
                  </div>
                </b-alert>
              </div>
              <div class="form-group">
                <b-alert show variant="info">
                  <h4 class="alert-heading">Choisir le jour limite pour les chiffres d'affaires</h4>
                  <hr>
                  <p>
                    Choissisez le jours limite pour les saisies chiffres d'affaires du mois précédent.
                    <br />
                    <br />
                    <b>Exemple</b> : le magasin SFR à une date limite au <b>17</b>.
                    Pour le mois de <b>février</b>, le magasin aura <b>jusqu'au 17 mars</b> pour remplir ses chiffres d'affaires.
                  </p>
                  <hr />
                  <label for="limit-sale-date">Quel jour voulez vous choisir ?</label>
                  <select class="form-control" id="limit-sale-date" v-model="selectDateLimitSale" @input="changeDateLimitSale">
                    <option v-for="(day, index) in 31" :key="index" :value="day">{{ day }}</option>
                  </select>
                  <div v-if="errorsForm.dateLimitSale">
                    <p class="form-error text-danger" v-for="(error, index) in errorsForm.dateLimitSale.errors" :key="index">{{ error }}</p>
                  </div>
                </b-alert>
              </div>
              <div class="form-group">
                <b-alert show variant="warning">
                  <h4 class="alert-heading">Déclaration de CA</h4>
                  <hr />
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="is-have-sale-yes" v-model="shop.isHaveSale" :checked="shop.isHaveSale === true"  v-bind:value="true">
                    <label class="form-check-label" for="is-have-sale-yes">
                      Oui, le magasin déclare des chiffres d'affaires.
                    </label>
                  </div>
                  <div class="form-check" >
                    <input class="form-check-input" type="radio" id="is-have-sale-no" v-model="shop.isHaveSale" :checked="shop.isHaveSale === false" v-bind:value="false">
                    <label class="form-check-label" for="is-have-sale-no">
                      Non, le magasin ne déclare <b>pas</b> de chiffres d'affaires.
                    </label>
                  </div>
                  <div v-if="errorsForm.isHaveSale">
                    <p class="form-error text-danger" v-for="(error, index) in errorsForm.isHaveSale.errors" :key="index">{{ error }}</p>
                  </div>
                </b-alert>
              </div>
            </div>
          </div>
        </div>

        <input type="submit" class="btn mt-4 ml-4 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  props: {
    shop: Object,
    id: Number,
    actionForm: String,
    selectedCompany: Number,
    selectedCategory: Number,
    selectedDateLimitSale: Number
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      formatDate: 'dd/MM/yyyy',
      errorsForm: [],
      selectCompany: this.selectedCompany,
      selectCategory: this.selectedCategory,
      selectDateLimitSale: this.selectedDateLimitSale
    }
  },
  watch: {
    selectedCompany: function() {
      this.selectCompany = this.selectedCompany
    },
    selectedCategory: function() {
      this.selectCategory = this.selectedCategory
    },
    selectedDateLimitSale: function() {
      this.selectDateLimitSale = this.selectedDateLimitSale
    }
  },
  created() {
    this.$store.dispatch('fetchCompanies', {
      perPage: null,
      page: null
    })
    this.$store.dispatch('fetchCategories', {
      perPage: null,
      page: null
    })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    changeCompany: function(){
      this.$emit('input',this.selectCompany)
    },
    changeCategory: function(){
      this.$emit('input',this.selectCategory)
    },
    changeDateLimitSale: function(){
      this.$emit('input',this.selectDateLimitSale)
    },
    initSubmit()
    {
      // Handle company & category
      this.shop.company = this.selectCompany
      this.shop.category = this.selectCategory
      this.shop.dateLimitSale = this.selectDateLimitSale

      // Handle date
      this.shop.openDate = moment(this.shop.openDate).format("YYYY-MM-DD")
      if(this.shop.closeDate) {
        this.shop.closeDate = moment(this.shop.closeDate).format("YYYY-MM-DD")
      } else {
        this.shop.closeDate = null
      }
      this.shop.hullDate = moment(this.shop.hullDate).format("YYYY-MM-DD")

      // If shop (create or update)
      if(this.actionForm === 'edit') {
        this.editShop()
      } else {
        this.createShop()
      }
    },
    /**
     * Create shop (submit form)
     */
    createShop() {
      this.showLoader()

      let model = {
        centerId: this.$store.getters.getChannel.center.id,
        shop: this.shop
      }

      this.$store.dispatch('createShop', model)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update shop (submit form)
     */
    editShop() {
      this.showLoader()

      let model = {
        centerId: this.$store.getters.getChannel.center.id,
        shop: this.shop
      }

      this.$store.dispatch('updateShop', model)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to shop-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'shop-list' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit') {
        value = 'Enregistrer les modifications'
      } else {
        value = 'Créer un commerce'
      }

      return value
    },
    ...mapState(['ChannelModule']),
    ...mapState(['CompanyModule']),
    ...mapState(['CategoryModule']),
    ...mapState(['ShopModule'])
  },
  components: {
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>

<style scoped>

</style>
